import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {   BrowserRouter as Router,
  useHistory, useLocation, useParams} from 'react-router-dom';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import Swal from 'sweetalert2';
import axios from 'axios';


// defino los axios generales
axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;

// función para guardar la variable local
function guardarEnLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function Login() {

  const history = useHistory();
  const {cuentaPL, setCuentaPL} = useVariablesGlobales();
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');

const [showPassword, setShowPassword] = useState(false);
const [olvidePass, setOlvidePass] = useState(false);
const [emailOlvide, setEmailOlvide] = useState('');

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

const {authTokenExist, setAuthTokenExist} = useVariablesGlobales();
const {authToken, setAuthToken} = useVariablesGlobales();

// Esta función evita que si estamos en el Home 

let token = localStorage.getItem('authLaravel');
  let auth = false;
  if(token !== null && token !== undefined && token !== '') {
    auth = true;
  }
  if(auth) {
    history.replace('/');
  };


const handleSubmit = async (event) => {
  
  event.preventDefault();
  try {
    const response = await axios.post('https://plsystem.quarkservers2.com/api/login_gate', {
      id_cuenta: cuentaPL,
      Email: email,
      Password: password
    });
    
    guardarEnLocalStorage('authLaravel', response.data.accessToken);
    guardarEnLocalStorage('idUsuario', response.data.user.id);
    switch (cuentaPL) {
      case "1":
          guardarEnLocalStorage('Distribuidor', response.data.distribuidor_electrico);
          guardarEnLocalStorage('Region', response.data.region_electrico);
          guardarEnLocalStorage('DistribuidorAlt', response.data.distribuidor_ni);
          guardarEnLocalStorage('RegionAlt', response.data.region_ni);
        break;
      case "3":
        guardarEnLocalStorage('Distribuidor', response.data.distribuidor_ni);
        guardarEnLocalStorage('Region', response.data.region_ni);
        guardarEnLocalStorage('DistribuidorAlt', response.data.distribuidor_electrico);
        guardarEnLocalStorage('RegionAlt', response.data.region_electrico);
      break;
    }
    
    setAuthToken(response.data.accessToken)
    setAuthTokenExist(true);
    
    let redireccion = response.data.redireccion;
    console.log(redireccion);
    
    switch (redireccion) {
      case 'gate_doble':
        history.replace('/acceso/doble');
        break;
      case 'gate_ni':
        history.replace('/acceso/ni');
        break;
      case 'gate_electrico':
        history.replace('/acceso/electrico');
        break;
        case 'usuario':
          history.replace('/');
        break;
      default:
        break;
    }
    
    
    
  } catch (error) {
    if (error.response.status === 401) {
        Swal.fire({
            title: '¡Usuario o contraseña incorrectos!',
            icon: 'error',
            confirmButtonColor: '#ff0000',
            confirmButtonText: 'Aceptar'
        });
    } else {
        console.error('Error al enviar la solicitud:', error);
        Swal.fire({
            title: '¡Hubo un error al enviar la solicitud!',
            icon: 'error',
            confirmButtonColor: '#ff0000',
            confirmButtonText: 'Aceptar'
        });
    }
}
};

const handleSubmitOlvide = async (event) => {
  
  event.preventDefault();
  try {
    const response = await axios.post('https://plsystem.quarkservers2.com/api/olvide_pass', {
      id_cuenta: cuentaPL,
      Email: emailOlvide
    });
    setEmailOlvide(false);
    Swal.fire({
      title: '¡Gracias si tu correo está registrado recibirás un Email con instrucciones para contniuar!',
      icon: 'success',
          confirmButtonColor: '#213746',
      confirmButtonText: 'Aceptar'
  }).then((result) => {
        if (result.isConfirmed) {
        window.location.reload();
        }
  });
    
  } catch (error) {
    console.log(error);
}
};

return (
  <div>
    <div className="rbt-elements-area bg-color-white rbt-section-gap">
      <div className="container">
          <div className="row gy-5 row--30 justify-content-center">

              <div className="col-lg-8 pr--120 pl--120">
                  <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                      <h3 className="title">Acceso </h3>
                      {!olvidePass ? (
                        <>
                        <form className="rbt-profile-row rbt-default-form row row--15" onSubmit={handleSubmit}>
                        <div className="form-group" style={{ padding: '0 25%' }}>
                        <label>Correo electrónico o Usuario *</label>
                            <input  type="text" 
                                    name="Email" 
                                    placeholder="" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}/>
                            
                        </div>
                        <div className="form-group" style={{ padding: '0 25%' }}>
                          <label>Contraseña *</label>
                          <div className="password-input-container">
                            <input
                            style={{marginBottom:'0px'}}
                              type={showPassword ? 'text' : 'password'}
                              name="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="password-input"
                            />
                            <a style={{paddingBottom:'0px', paddingRight:'10px'}}
                              className="password-toggle-btn"
                              onClick={togglePasswordVisibility}
                            >
                              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </a>
                            </div>
                            <a className='btn btn-link' style={{ float: 'right' }} onClick={() => {setOlvidePass(true)}}>Olvide mi contraseña</a>
                        </div>
                        <div className="form-submit-group mt--10" style={{ padding: '0 35%' }}>
                            <button type="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100">
                                <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Entrar</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                </span>
                            </button>
                        </div>
                    </form>
                    
                        </>
                      ):(
                       <>
                        <form className="rbt-profile-row rbt-default-form row row--15" onSubmit={handleSubmitOlvide}>
                          <div className="form-group" style={{ padding: '0 25%' }}>
                          <label>Correo electrónico o Usuario *</label>
                              <input  type="text" 
                                      name="Email" 
                                      placeholder="" 
                                      value={emailOlvide}
                                      onChange={(e) => setEmailOlvide(e.target.value)}/>
                              
                          </div>
                          <div className="form-submit-group" style={{ padding: '0 35%' }}>
                              <button type="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100">
                              Recuperar Contraseña
                              </button>
                          </div>
                      </form>
                      
                       </>
                      )}
                  </div>

                  <h6 className='text-center mt--20' style={{textTransform:'uppercase', marginBottom:'0px'}}>El acceso a este sitio es solo con invitación.</h6>
                  <p className='text-center'>Si requieres acceso a él, ponte en contacto con tu Gerente de marketing Regional Panduit.</p>
              </div>

          </div>
      </div>
    </div>
  </div>
);
}

export function Gate() {
const { programa } = useParams();
const [mostrarElectrico, setMostrarElectrico]= useState(false);
const [mostrarNi, setMostrarNi]= useState(false);
const [urlRedireccionEl, setUrlRedireccionEl]= useState('');
const [urlRedireccionNi, setUrlRedireccionNi]= useState('');

// Busco variables locales
const id_usuario = JSON.parse(localStorage.getItem('idUsuario'))
const token = JSON.parse(localStorage.getItem('authLaravel'))
const local_distribuidor = JSON.parse(localStorage.getItem('Distribuidor'))
const local_region = JSON.parse(localStorage.getItem('Region'))
const local_distribuidorAlt = JSON.parse(localStorage.getItem('DistribuidorAlt'))
const local_regionAlt = JSON.parse(localStorage.getItem('RegionAlt'))

const [idUsuario, setIdUsuario]= useState(id_usuario);
const [authLaravel, setAuthLaravel]= useState(token);
const [distribuidor, setDistribuidor]= useState(local_distribuidor);
const [region, setRegion]= useState(local_region);
const [distribuidorAlt, setDistribuidorAlt]= useState(local_distribuidorAlt);
const [regionAlt, setRegionAlt]= useState(local_regionAlt);



// Textos default
const [tituloInstrucciones, setTituloInstrucciones]= useState('');
const [textoInstrucciones, setTextoInstrucciones]= useState('');
// Switch según el programa
useEffect(() => {
  setUrlRedireccionEl('https://pl-electrico.panduitlatam.com/cambiar_programa'+id_usuario+'/'+token+'/'+local_distribuidor+'/'+local_region);
  setUrlRedireccionNi('https://p-learning.panduitlatam.com/cambiar_programa'+id_usuario+'/'+token+'/'+local_distribuidor+'/'+local_region);
  switch (programa) {
    case 'doble':
      setMostrarElectrico(true);
      setMostrarNi(true);
      setTituloInstrucciones('Manuel, perteneces a ambos programas.');
      setTextoInstrucciones('¡Elige una de las dos opciones para comenzar!');
      break;
    case 'ni':
      setMostrarElectrico(false);
      setMostrarNi(true);
      setTituloInstrucciones('Manuel, parece que estás en el lugar equivocado.');
      setTextoInstrucciones('¡Entra en PLearning para continuar!');
      break;
    case 'electrico':
      setMostrarElectrico(true);
      setMostrarNi(false);
      setTituloInstrucciones('Manuel, parece que estás en el lugar equivocado.');
      setTextoInstrucciones('¡Entra en PLearning Soluciones eléctricas para continuar!');
      break;
    default:
      break;
  }
}, [programa]); // El efecto solo se ejecuta cuando cambia el valor de programa



return (
  <div className="rbt-elements-area bg-color-white rbt-section-gap">
  <div className="container">
    <div className="row gy-5 row--30 justify-content-center">
      <div className="col-lg-8 pr--50 pl--50">
        <div className="rbt-contact-form contact-form-style-1 max-width-auto text-center">
          <p style={{ fontWeight: '600', fontSize: '25px', color: '#3d3d3d', textAlign:'left' }} className="mb--0">
            {tituloInstrucciones}
          </p>
          <p style={{ fontWeight: '500', fontSize: '25px', color: '#3d3d3d', textAlign:'left' }} className="mb--0">
            {textoInstrucciones}
          </p>

          <div className="program-options mt--50 d-flex justify-content-center">
            {mostrarElectrico ? (
              <div className="option">
                <h4 className="mb--0" style={{ fontWeight: '500' }}>PLearning, Soluciones</h4>
                <p className="ml--35 mr--35" style={{ fontSize: '22px' }}>Entrenamientos virtuales con Especialidad Eléctrica</p>
                <a href={urlRedireccionEl} className="rbt-btn btn-md btn-gradient hover-icon-reverse" style={{ background: 'linear-gradient(to right, rgb(204, 153, 0), rgb(240, 179, 35))' }}>
                  <span className="icon-reverse-wrapper">
                    <span className="btn-text">Acceder</span>
                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                  </span>
                </a>
              </div>
            ) : null}
              
            <div className="divider"></div>

            {mostrarNi ? (
            <div className="option">
              <h4 className="mb--0" style={{fontWeight:'500'}}>PLearning</h4>
              <p className='ml--35 mr--35' style={{fontSize:'22px'}}>Entrenamientos virtuales con Especialidad en Enterprise</p>
              <a href={urlRedireccionNi} className="rbt-btn btn-md btn-gradient hover-icon-reverse">
                <span class="icon-reverse-wrapper">
                  <span class="btn-text">Acceder</span>
                  <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                  <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                </span>
              </a>
            </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
)
}

export function CambiarPrograma () {
const { id, token, distribuidor, region } = useParams();
const history = useHistory(); // Para redirigir

useEffect(() => {
  // Guardar los valores en localStorage
  localStorage.setItem('idUsuario', JSON.stringify(id));
  localStorage.setItem('authLaravel', JSON.stringify(token));
  localStorage.setItem('Distribuidor', JSON.stringify(distribuidor));
  localStorage.setItem('Region', JSON.stringify(region));
}, [id, token, distribuidor, region]);

// No se renderiza nada, solo guarda los valores en localStorage
if (localStorage.getItem('idUsuario')) {
  history.push('/'); // Redirige al inicio
}
};

export function OlvidePass() {
    return (
      <div>
        <h2>Olvidé mi contraseña</h2>
        <p>Componente no utilizado eliminar</p>
      </div>
    );
  }

  export function RestaurarPass() {
    const { id, di } = useParams();
    const [hasSentRequest, setHasSentRequest] = useState(false);
    const history = useHistory(); 
    useEffect(() => {
      if (!hasSentRequest && id && di) {
        axios.post('https://plsystem.quarkservers2.com/api/restaurar_pass', {
          id: id,
          di: di
        })
        .then(function (response) {
          Swal.fire({
            title: '¡Tu contraseña ha sido restaurada a '+response.data+'!',
            icon: 'success',
            confirmButtonColor: '#213746',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
             if (result.isConfirmed) {
              history.replace('/login');
             }
        });
          setHasSentRequest(true);
        })
        .catch(function (error) {
          //console.error(error);
        });
      }
    }, [id, di, hasSentRequest]);

    return (
      <div>
        <div className="rbt-error-area rbt-section-gap pb--0 pt--60">
            <div className="error-area">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-10">
                            <div className="thumbnail">
                                <img src="/assets/images/micrositio/PLearning_250_circulo_2.gif" alt="Image" />
                            </div>
                            
                            <br></br>
                            <div class="breadcrumb-inner text-center">
                           <h2>{hasSentRequest ? ('Contraseña restaurada'):('Restableciendo contraseña')}</h2>
                        </div>
                            <p>{hasSentRequest ? ('Tu contraseña ha sido restaurada, por favor revisa tu correo'):('Por favor espera...')}</p>
                            {/* <a className="rbt-btn btn-gradient icon-hover" href="/">
                                <span className="btn-text">Ir al Inicio</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      
    );
  }

export default Login;