import {React, useState, useEffect, useCallback } from 'react';
import { SeccionUno, SeccionDos } from "../components/sections";
import DatosPerfil from '../components/EChartsBarChart';
import Swal from 'sweetalert2';

import { Container, Row, Col, Carousel, Card, Button, Form} from 'react-bootstrap';
import {   BrowserRouter as Router,
  useHistory} from 'react-router-dom';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import axios from 'axios';
import Cropper from 'react-easy-crop';

export function Perfil() {

    const [datosUsuario, setDatosUsuario] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [puntajeUsuario, setPuntajeUsuario] = useState(null);
    const [sesionesVisualizaciones, setSesionesVisualizaciones] = useState(null);
    const [sesionesEvaluaciones, setSesionesEvaluaciones] = useState(null);
    const [triviasRespuestas, setTirivasRespuestas] = useState(null);
    const [triviasGanadores, setTriviasGanadores] = useState(null);
    const [jackpotIntentos, setJackpotIntentos] = useState(null);
    const [puntosExtra, setPuntosExtra] = useState(null);
    const [distribuidor, setDistribuidor] = useState(null);
    const [recargarDatos, setRecargarDatos] = useState(false);
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    const [editando, setEditando] = useState(false);
    const [enviandoFoto, setEnviandoFoto] = useState(false);
    const [actualizandoPass, setActualizandoPass] = useState(false);
    const [actualizandoPerfil, setActualizandoPerfil] = useState(false);
    const [actualizandoFoto, setActualizandoFoto] = useState(false);
    const [formData, setFormData] = useState({
        id_usuario: id_local,
        legacy_id: '',
        telefono: '',
        fecha_nacimiento: null,
        photo: null // Usamos null para representar que inicialmente no hay foto seleccionada
    });

    // Variables de crop de imágen
        const [image, setImage] = useState(null);
        const [crop, setCrop] = useState({ x: 0, y: 0 });
        const [zoom, setZoom] = useState(1);
        const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    
    // Funciones del crop de imágen
    const createImage = url =>
        new Promise((resolve, reject) => {
          const image = new Image();
          image.addEventListener('load', () => resolve(image));
          image.addEventListener('error', error => reject(error));
          image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
          image.src = url;
        });

    const getCroppedImg = async (imageSrc, crop) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
        
        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea;
        canvas.height = safeArea;
        
        // translate canvas context to a central location to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.translate(-safeArea / 2, -safeArea / 2);
        
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );
        
        const data = ctx.getImageData(0, 0, safeArea, safeArea);
        
        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = crop.width;
        canvas.height = crop.height;
        
        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            -safeArea / 2 + image.width * 0.5 - crop.x,
            -safeArea / 2 + image.height * 0.5 - crop.y
        );
        
        // As Base64 string
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
            resolve(file);
            }, 'image/jpeg');
        });
    };

        
        
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    
    const handleUpload = async () => {
        setEnviandoFoto(true);
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        const formData = new FormData();
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        formData.append('photo', croppedImage, 'croppedImage.jpeg');
        formData.append('id_usuario', id_local);
        
        console.log(formData);
        axios.post('https://plsystem.quarkservers2.com/api/actualizar_imagen_perfil', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        })
        .then(response => {
        setEnviandoFoto(false);
        setImage(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setRecargarDatos(!recargarDatos);
        Swal.fire({
            title: '¡Foto guardada!',
            icon: 'success',
            confirmButtonColor: '#213746',
            confirmButtonText: 'Aceptar'
        });
        setActualizandoFoto(false);
        })        
        .catch(error => {
        console.error(error);
        });
    };
      
      


    const toggleEdicion = () => {
        setEditando(!editando);
    };
    
    const [formPass, setFormPass] = useState({
        id_usuario: id_local,
        old_pass: '',
        new_pass: '',
        confirm_pass: ''
    });

    const handleChangePass = (e) => {
        const { id, value } = e.target;
        // Si el input es de tipo file, actualizamos el estado de la foto
        
        setFormPass({
            ...formPass,
            [id]: value
        });
    };

    const handleSubmitPass = async (e) => {
        e.preventDefault();
        setActualizandoPass(true);
        try {
            
          const response = await axios.post('https://plsystem.quarkservers2.com/api/actualizar_pass_perfil', formPass, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          });
          setActualizandoPass(false);
          //console.log(response);
          if(response.data=='ok'){
            Swal.fire({
                title: '¡Contraseña actualizada!',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
          }
          if(response.data=='new_pass'){
            Swal.fire({
                title: '¡La nueva contraseña no coincide!',
                icon: 'error',
                confirmButtonColor: '#ff0000',
                confirmButtonText: 'Aceptar'
                });
          }

          if(response.data=='old_pass'){
            Swal.fire({
                title: '¡Tu contraseña actual, no coincide!',
                icon: 'error',
                confirmButtonColor: '#ff0000',
                confirmButtonText: 'Aceptar'
                });
          }

          if(response.data=='user'){
            Swal.fire({
                title: 'No se pudo identificar al usuario',
                icon: 'error',
                confirmButtonColor: '#ff0000',
                confirmButtonText: 'Aceptar'
                });
          }

          setFormPass({
            ...formPass,
            old_pass: '',
            new_pass: '',
            confirm_pass: ''
        });
          
          setRecargarDatos(!recargarDatos);
        } catch (error) {
          console.error('Error al subir archivo', error);
          Swal.fire({
            title: '¡No se pudo enviar la información!',
            icon: 'error',
            confirmButtonColor: '#ff0000',
            confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        // Si el input es de tipo file, actualizamos el estado de la foto
        if (type === 'file') {
            setFormData({
                ...formData,
                photo: files[0] // Tomamos solo el primer archivo seleccionado
            });
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(formData);
        setActualizandoPerfil(true);
        try {
            
          const response = await axios.post('https://plsystem.quarkservers2.com/api/actualizar_usuario_perfil', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          });
          setActualizandoPerfil(false);
          //console.log(response);
          Swal.fire({
            title: '¡Datos actualizados!',
            icon: 'success',
            confirmButtonColor: '#213746',
            confirmButtonText: 'Aceptar'
            });
          setRecargarDatos(!recargarDatos);
          setEditando(!editando);
        } catch (error) {
            Swal.fire({
                title: 'No se pudo actualizar',
                icon: 'error',
                confirmButtonColor: '#FF0000',
                confirmButtonText: 'Aceptar'
                });
          console.error('Error al subir archivo', error);
        }
    };

    

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/full_check', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                id: id_local,
                id_temporada: 9
            }
        })
        .then(function (response) {
            setDatosUsuario(response.data);
            setFormData({
                id_usuario: response.data.usuario.id,
                legacy_id: response.data.usuario.legacy_id,
                telefono: response.data.usuario.telefono,
                fecha_nacimiento: response.data.usuario.fecha_nacimiento,
            });
        })
        .catch(function (error) {
            // handle error
        });
    }, [recargarDatos]);
    
    const detallesPuntaje = async () => {
        try {
            const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            const response = await axios.get('https://plsystem.quarkservers2.com/api/detalles_puntaje_usuario', {
                params: {
                    id_usuario: id_local,
                    id_temporada: 9
                }
            });
    
            const { sesiones, evaluaciones, trivias_respuestas, puntos_extra } = response.data;
    
            // Objeto para almacenar el puntaje total por sesión
            const puntajesPorSesion = {};
            evaluaciones.forEach(evaluacion => {
                const { id_sesion, puntaje } = evaluacion;
                puntajesPorSesion[id_sesion] = (puntajesPorSesion[id_sesion] || 0) + parseInt(puntaje, 10);
            });
    
            const sesionesConPuntajeTotal = sesiones.map(sesion => {
                const puntajeTotalSesion = puntajesPorSesion[sesion.id_sesion] || 0;
                const puntajeVisualizacion = parseInt(sesion.puntaje, 10) || 0;
                const totalPuntaje = puntajeTotalSesion + puntajeVisualizacion;
                return { ...sesion, puntajeTotal: puntajeTotalSesion, totalPuntaje };
            });
    
            // Objeto para almacenar el puntaje total por trivia
            const puntajesPorTrivia = {};
            trivias_respuestas.forEach(trivia => {
                const { id_trivia, puntaje } = trivia;
                if (!puntajesPorTrivia[id_trivia]) {
                    puntajesPorTrivia[id_trivia] = 0;
                }
                puntajesPorTrivia[id_trivia] += parseInt(puntaje, 10);
            });
    
            // Mapear las trivias con el puntaje total
            const triviasConPuntajeTotal = [];
            for (const id_trivia in puntajesPorTrivia) {
                const trivia = trivias_respuestas.find(t => t.id_trivia === parseInt(id_trivia, 10));
                triviasConPuntajeTotal.push({ ...trivia, puntajeTotal: puntajesPorTrivia[id_trivia] });
            }
            // Mapear puntos extra
            
    
            // Establecer el estado
            setSesionesVisualizaciones(sesionesConPuntajeTotal);
            setSesionesEvaluaciones(evaluaciones);
            setTirivasRespuestas(triviasConPuntajeTotal);
            setTriviasGanadores(response.data.trivias_ganadores);
            setJackpotIntentos(response.data.jackpot_intentos);
            setPuntosExtra(response.data.puntos_extra);
        } catch (error) {
            // Manejar el error
        }
    };
    
    
    
    
    
    useEffect(() => {
        detallesPuntaje();
    }, [datosUsuario]);
    
    useEffect(() => {
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/puntaje_usuario', {
            params: {
                id_usuario: id_local,
                id_temporada: 9
            }
        })
        .then(function (response) {
            console.log(response.data);
            setPuntajeUsuario(response.data);
        })
        .catch(function (error) {
            // handle error
        });
    }, [datosUsuario]);

    const formatNumberWithCommas = (number) => {
        if (number == null) return '0'; // Devuelve '0' si el número no está definido
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    
  

  

  if(datosUsuario && puntajeUsuario){
    let puntajeTotal = puntajeUsuario.evaluaciones+puntajeUsuario.visualizaciones+puntajeUsuario.trivia+puntajeUsuario.jackpots+puntajeUsuario.extra;
    return (
        <div>
    
          <div className="rbt-page-banner-wrapper">
              <div className="rbt-banner-image"></div>
          </div>
    
          <div className="rbt-dashboard-area rbt-section-overlayping-top rbt-section-gapBottom pb--40">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          {/*Start Dashboard Top  */}
                          <div className="rbt-dashboard-content-wrapper">
                              <div className="tutor-bg-photo bg_image bg_image--23 height-350"></div>
                              {/*Start Tutor Information  */}
                              <div className="rbt-tutor-information">
                                  <div className="rbt-tutor-information-left">
                                      <div className="thumbnail rbt-avatars size-lg">
                                          <img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosUsuario.usuario.imagen}`} alt="Instructor" />
                                      </div>
                                      <div className="tutor-content">
                                        <h5 className="title"> {datosUsuario.usuario.nombre} {datosUsuario.usuario.apellidos}
                                        </h5>
                                        <h6 className="title">{datosUsuario.distribuidor.nombre}</h6>
                                    </div>
                                  </div>
                              </div>
                              {/*End Tutor Information  */}
                          </div>
                          {/*End Dashboard Top  */}
    
                          <div className="row g-5">
                              <div className="col-lg-3">
                                  {/*Start Dashboard Sidebar  */}
                                  <div className="rbt-default-sidebar sticky-top rbt-shadow-box">
                                      <div className="inner">
                                          <div className="content-item-content">
                                              <div className="rbt-default-sidebar-wrapper">
                                                  <div className="section-title mb--20">
                                                      <h6 className="rbt-title-style-2">Bienvenido, {datosUsuario.usuario.nombre}</h6>
                                                  </div>
                                                  <nav className="mainmenu-nav">
                                                      <ul className="dashboard-mainmenu nav nav-tabs tab-button" role="tablist">

                                                            <li className="nav-item tabs__tab" role="presentation">
                                                                <button className="nav-link active" id="home-tab-5" data-bs-toggle="tab" data-bs-target="#home5" type="button" role="tab" aria-controls="home5" aria-selected="true">
                                                                    <i className="feather-home"></i><span> Puntos y Créditos</span>
                                                                </button>
                                                            </li>

                                                            <li className="nav-item tabs__tab" role="presentation">
                                                                <button className="nav-link " id="profile-tab5" data-bs-toggle="tab" data-bs-target="#profile5" type="button" role="tab" aria-controls="profile5" aria-selected="false" style={{paddingRight:'6rem'}}>
                                                                    <i className="feather-user"></i><span> Mi Perfil</span>
                                                                </button>
                                                            </li>
                                                            <li className="nav-item tabs__tab" role="presentation">
                                                                <button className="nav-link " id="imagen-tab" data-bs-toggle="tab" data-bs-target="#imagen" type="button" role="tab" aria-controls="imagen" aria-selected="false" style={{paddingRight:'6rem'}}>
                                                                    <i className="feather-image"></i><span> Foto de Perfil</span>
                                                                </button>
                                                            </li>
                                                            <li className="nav-item tabs__tab" role="presentation">
                                                                <button className="nav-link " id="password-tab" data-bs-toggle="tab" data-bs-target="#password" type="button" role="tab" aria-controls="password" aria-selected="false">
                                                                    <i className="feather-lock"></i><span> Cambiar contraseña</span>
                                                                </button>
                                                            </li>
                                                            {console.log(datosUsuario.sucripcion.funcion)}
                                                            {datosUsuario.sucripcion.funcion=='lider' ? (
                                                            <li className="" role="">
                                                                <a className="nav-link " href='/lider'>
                                                                    <i className="feather-star"></i> <span> Panel Lider</span>
                                                                </a>
                                                            </li>
                                                            ):null}
                                                            {datosUsuario.sucripcion.funcion=='super_lider' ? (
                                                            <li className="" role="">
                                                                <a className="nav-link " href='/superlider'>
                                                                    <i className="feather-star"></i> <span> Super Lider</span>
                                                                </a>
                                                            </li>
                                                            ):null}

                                                            {/* <li className="nav-item tabs__tab" role="presentation">
                                                                <button className="nav-link " id="actividad-tab5" data-bs-toggle="tab" data-bs-target="#actividades" type="button" role="tab" aria-controls="actividades" aria-selected="false">
                                                                    <i className="feather-book-open"></i> <span> Actividades</span>
                                                                </button>
                                                            </li> */}

                                                            {/* <li className="nav-item tabs__tab " role="presentation">
                                                                <a className="nav-link" href="/champions"><i className="feather-bookmark"></i><span>Champions</span></a>
                                                            </li> */}
                                                        
                                                        
                                                          {/* <li><a href="student-reviews.html"><i className="feather-star"></i><span>Reviews</span></a></li>
                                                          <li><a href="student-my-quiz-attempts.html"><i className="feather-help-circle"></i><span>My Quiz Attempts</span></a></li>
                                                          <li><a href="student-order-history.html"><i className="feather-shopping-bag"></i><span>Order History</span></a></li> */}
                                                      </ul>
                                                  </nav>
                                                  <div className="section-title mt--40 mb--20">
                                                      <h6 className="rbt-title-style-2">User</h6>
                                                  </div>
    
                                                  <nav className="mainmenu-nav">
                                                      <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                                          {/* <li><a href="/lider"><i className="feather-settings"></i><span>Líderes</span></a></li> */}
                                                          <li><a href="#"><i className="feather-log-out"></i><span>Cerrar sesión</span></a></li>
                                                      </ul>
                                                  </nav>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  {/*End Dashboard Sidebar  */}
                              </div>
                              <div className="col-lg-9 rainbow-tab-content tab-content">

                                    {/*Creditos */}
                                    <div className="tab-pane fade show active" id="home5" role="tabpanel" aria-labelledby="home-tab-5">
                                        <div className="content">
                                            <div className="section-title">
                                                <h3 className="title text-black">Puntos y Créditos</h3>
                                            </div>
                                            
                                            <div className="col-lg-12 mt--30">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <div className="row">
                                                          <div className="col-6">
                                                            <h2 className="rbt-title-style-3" style={{fontSize:'26px'}}>
                                                                {/* Icono de trading */}
                                                                
                                                                {formatNumberWithCommas(puntajeTotal)} puntos Totales
                                                                <i className="fas fa-chart-line mr-2 " style={{paddingLeft:'10px'}}></i>
                                                            </h2>  
                                                          </div>
                                                          <div className="col-6">
                                                            <h2 className="rbt-title-style-3" style={{fontSize:'26px'}}>
                                                                {/* Icono de trading */}
                                                                
                                                                {formatNumberWithCommas(puntajeTotal)} Créditos 
                                                            </h2>  
                                                          </div>
                                                        </div>
                                                        <div style={{ marginTop: '10px', height:'400px' }}>
                                                            {/* Añadimos un margen superior para separarlo del título */}
                                                            <h5 style={{ textAlign: 'left' }}>Resumen</h5>
                                                            <span className="d-inline-block px-3" style={{ fontWeight: '700' }}>
                                                                Sesiones {formatNumberWithCommas(puntajeUsuario.visualizaciones)}
                                                            </span>
                                                            <span className="d-inline-block px-3" style={{ fontWeight: '700' }}>
                                                                Evaluaciones {formatNumberWithCommas(puntajeUsuario.evaluaciones)}
                                                            </span>
                                                            <span className="d-inline-block px-3" style={{ fontWeight: '700' }}>
                                                                Trivias {formatNumberWithCommas(puntajeUsuario.trivia)}
                                                            </span>
                                                            <span className="d-inline-block px-3" style={{ fontWeight: '700' }}>
                                                                Jackpot {formatNumberWithCommas(puntajeUsuario.jackpots)}
                                                            </span>
                                                            <span className="d-inline-block px-3" style={{ fontWeight: '700' }}>
                                                                Extra {formatNumberWithCommas(puntajeUsuario.extra)}
                                                            </span>
                                                            <DatosPerfil />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div className='container mb-4'>
                                                <div className="row mb-4 justify-content-center">
                                                    <div className="col-12 text-center">
                                                        <div className="rbt-accordion-style rbt-accordion-02 accordion">
                                                            <div className="accordion" id="accordionAnexos">
                                                                <div className="accordion-item card">
                                                                    <h2 className="accordion-header card-header" id="headingOne1">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                                                                        Sesiones / Evaluaciones
                                                                    </button>
                                                                    </h2>
                                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionAnexos">
                                                                        <div className="accordion-body card-body">
                                                                            <div className='row'>
                                                                                <div className='table-responsive mobile-table-750'>
                                                                                <table className="rbt-table table table-borderless">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{textAlign:'left', paddingLeft:'15px'}}>Sesión</th>
                                                                                            <th>Visualización</th>
                                                                                            <th>Fecha</th>
                                                                                            <th>Evaluación</th>
                                                                                            <th>Total</th> {/* Nueva columna para el puntaje total */}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {sesionesVisualizaciones && sesionesVisualizaciones.map((sesion, index) => (
                                                                                            <tr key={index}>
                                                                                                <td  style={{ textAlign: 'left', paddingLeft: '15px', fontWeight:'600'}}>{sesion.titulo}</td>
                                                                                                <td><i class="feather-eye" style={{fontWeight:'700', color:'#213746'}}></i> {sesion.puntaje}</td>
                                                                                                <td><i class="feather-calendar" style={{fontWeight:'700', color:'#213746'}}></i> {sesion.fecha_ultimo_video}</td>
                                                                                                <td><i class="feather-check-square" style={{fontWeight:'700', color:'#213746'}}></i> {sesion.puntajeTotal}</td>
                                                                                                <td style={{fontWeight:'700'}}>{sesion.totalPuntaje}</td> 
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                                {/* <br></br>
                                                                                    <div className="col-12 text-center">
                                                                                        <table class="rbt-table table table-borderless">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th style={{textAlign:'left'}}>Total Visualizaciones <i class="feather-eye" style={{fontWeight:'700', color:'#213746'}}></i></th>
                                                                                                    <td>{puntajeUsuario.visualizaciones}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th style={{textAlign:'left'}}>Total Evaluaciones <i class="feather-check-square" style={{fontWeight:'700', color:'#213746'}}></i></th>
                                                                                                    <td>{puntajeUsuario.evaluaciones}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th style={{textAlign:'left'}}>Total</th>
                                                                                                    <td>{parseInt(puntajeUsuario.visualizaciones)+parseInt(puntajeUsuario.evaluaciones)}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item card">
                                                                    <h2 className="accordion-header card-header" id="headingOne1">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne1">
                                                                        Trivias
                                                                    </button>
                                                                    </h2>
                                                                    <div id="collapseOne2" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionAnexos">
                                                                        <div className="accordion-body card-body">
                                                                        <table className="rbt-table table table-borderless">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'left', paddingLeft: '15px' }}>Trivia</th>
                                                                                    <th>Ganador</th>
                                                                                    <th>Total</th> {/* Nueva columna para el puntaje total */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {triviasRespuestas && triviasRespuestas.map((trivia, index) => (
                                                                                    <tr key={index}>
                                                                                        <td  style={{ textAlign: 'left', paddingLeft: '15px', fontWeight:'600'}}>{trivia.titulo}</td>
                                                                                        <td>
                                                                                            {triviasGanadores && triviasGanadores.map((ganador, ganadorIndex) => (
                                                                                                ganador.id_trivia === trivia.id ? (
                                                                                                    <p key={ganadorIndex}>Ganador</p>
                                                                                                ) : (
                                                                                                    <p key={ganadorIndex}>-</p>
                                                                                                )
                                                                                            ))}
                                                                                        </td>
                                                                                        <td><i class="feather-check-square" style={{fontWeight:'700', color:'#213746'}}></i>  {trivia.puntajeTotal}</td> {/* Mostrar el puntaje total aquí */}
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        {/* <table className='table table-bordered ms-auto'>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Total Trivias</th>
                                                                                    <td>{puntajeUsuario.trivia}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item card">
                                                                    <h2 className="accordion-header card-header" id="headingOne1">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne1">
                                                                        Jackpot
                                                                    </button>
                                                                    </h2>
                                                                    <div id="collapseOne3" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionAnexos">
                                                                        <div className="accordion-body card-body">
                                                                        <table className="rbt-table table table-borderless">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'left', paddingLeft: '15px' }}>Jackpot</th>
                                                                                    <th>Resultado</th>
                                                                                    <th>Puntos</th> 
                                                                                    <th>Fecha</th> 
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {jackpotIntentos && jackpotIntentos.map((intento, index) => (
                                                                                <tr key={index}>
                                                                                    <td  style={{ textAlign: 'left', paddingLeft: '15px', fontWeight:'600'}}>{intento.titulo}</td>

                                                                                    <td>
                                                                                        <img style={{marginRight: '5px'}} src={`/assets/images/jackpot/slot_${intento.slot_1}.svg`} width='40px' alt="" /> 
                                                                                        <img style={{marginRight: '5px'}} src={`/assets/images/jackpot/slot_${intento.slot_2}.svg`} width='40px' alt="" /> 
                                                                                        <img style={{marginRight: '5px'}} src={`/assets/images/jackpot/slot_${intento.slot_3}.svg`} width='40px' alt="" /> 
                                                                                    </td>
                                                                                    <td>{intento.puntaje}</td>
                                                                                    <td>{intento.fecha_registro}</td>
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                        </table>
                                                                        {/* <table className='table table-bordered ms-auto'>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Total Jackpots</th>
                                                                                    <td>{puntajeUsuario.jackpots}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item card">
                                                                    <h2 className="accordion-header card-header" id="headingOne1">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4" aria-expanded="false" aria-controls="collapseOne1">
                                                                        Puntos extra
                                                                    </button>
                                                                    </h2>
                                                                    <div id="collapseOne4" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionAnexos">
                                                                        <div className="accordion-body card-body">
                                                                        <table className="rbt-table table table-borderless">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Concepto</th>
                                                                                    <th>Puntos</th> 
                                                                                    <th>Fecha</th> 
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {puntosExtra && puntosExtra.map((concepto, index) => (
                                                                                <tr key={index}>
                                                                                    <td >{concepto.concepto}</td>
                                                                                    <td>{concepto.puntos}</td>
                                                                                    <td>{concepto.fecha_registro}</td>
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                        </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                     {/*Perfil  */}
                                    <div className="tab-pane fade" id="profile5" role="tabpanel" aria-labelledby="profile-tab5">
                                        <div className="content">
                                            <div className="section-title">
                                                <h4 className="rbt-title-style-3">Editar Perfil</h4>
                                            </div>
        
                                            <div className="rainbow-tab-content tab-content">
                                                <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                <div className={`collapse ${editando ? '' : 'show'}`}>
                                                    <table className='rbt-table table table-borderless'>
                                                        <tbody>
                                                            <tr>
                                                                <th style={{fontWeight:'700'}}>Nombre</th>
                                                                <td>{datosUsuario.usuario.nombre} {datosUsuario.usuario.apellidos}</td>
                                                            </tr>
                                                            <tr >
                                                                <th style={{fontWeight:'700'}}>Usuario</th>
                                                                <td>{datosUsuario.usuario.legacy_id}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{fontWeight:'700'}}>Teléfono</th>
                                                                <td>{datosUsuario.usuario.telefono}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{fontWeight:'700'}}>Fecha Nacimiento</th>
                                                                <td>{datosUsuario.usuario.fecha_nacimiento}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button className="rbt-btn btn-gradient" onClick={toggleEdicion}>Editar</button>
                                                </div>
                                                <div className={`collapse ${editando ? 'show' : ''}`}>
                                                          {/* Start Profile Row  */}
                                                    <form onSubmit={handleSubmit} className="rbt-profile-row rbt-default-form row row--15">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="nombre">Nombre (s)</label>
                                                                <input type="text" value={datosUsuario.usuario.nombre} readOnly />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="apellidos">Apellidos (s)</label>
                                                                <input type="text" value={datosUsuario.usuario.apellidos} readOnly />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="legacy_id">Usuario</label>
                                                                <input id="legacy_id" type="text"  value={formData.legacy_id} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="telefono">Teléfono</label>
                                                                <input id="telefono" type="tel"  value={formData.telefono} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="fecha_nacimiento">Fecha Nacimiento</label>
                                                                <input id="fecha_nacimiento" type="date" value={formData.fecha_nacimiento} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-6 mt--20">
                                                            </div>
                                                            <div className="col-6 mt--20 d-flex justify-content-end">
                                                                <div className="rbt-form-group ml-auto">
                                                                    <button type="submit" className="rbt-btn btn-gradient" disabled={actualizandoPerfil}>{actualizandoPerfil ? 'Actualizando...' : 'Actualizar'}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    {/* End Profile Row  */}

                                                    
                                                </div>
                                               

                                               
                                                
                                                    
                                                </div>
        
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
                                        {/* Start Profile Row  */}
                                        <form action="#" className="rbt-profile-row rbt-default-form row row--15" onSubmit={handleSubmitPass}>
                                            <div className="col-12">
                                                <div className="rbt-form-group">
                                                    <label htmlFor="old_pass">Contraseña Actual</label>
                                                    <input id="old_pass" type="password" placeholder="" value={formPass.old_pass} onChange={handleChangePass}/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="rbt-form-group">
                                                    <label htmlFor="new_pass">Nueva contraseña</label>
                                                    <input id="new_pass" type="password" placeholder="" value={formPass.new_pass} onChange={handleChangePass}/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="rbt-form-group">
                                                    <label htmlFor="confirm_pass">Confirmar Contraseña</label>
                                                    <input id="confirm_pass" type="password" placeholder="" value={formPass.confirm_pass} onChange={handleChangePass} />
                                                </div>
                                            </div>
                                            <div className="col-12 mt--10">
                                                <div className="rbt-form-group">
                                                    <button type='submit' className="rbt-btn btn-gradient"  disabled={actualizandoPass}>{actualizandoPass ? 'Actualizando...' : 'Actualizar'}</button>
                                                </div>
                                            </div>
                                        </form>
                                        {/* End Profile Row  */}
                                    </div>

                                    <div className="tab-pane fade" id="imagen" role="tabpanel" aria-labelledby="imagen-tab">
                                        <div className={actualizandoFoto ? ('d-none'): ('')}>
                                            <div className='row justify-content-center'>
                                                <div className='col-6'>
                                                    <img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosUsuario.usuario.imagen}`} className='rounded-circle' width='300px' alt="Instructor" />
                                                    <br></br>
                                                    <button type='button' className='rbt-btn rbt-switch-btn btn-gradient btn-sm hover-transform-none' onClick={() => {setActualizandoFoto(true)}}>Editar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={!actualizandoFoto ? ('d-none'): ('')}>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))}
                                            />
                                            {image && (
                                                <div>
                                                <div className="crop-container">
                                                    <Cropper
                                                    image={image}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={1}
                                                    cropShape="round"
                                                    showGrid={false}
                                                    onCropChange={setCrop}
                                                    onZoomChange={setZoom}
                                                    onCropComplete={onCropComplete}
                                                    />
                                                </div>
                                                <div className="controls">
                                                    <input
                                                    type="range"
                                                    value={zoom}
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    aria-labelledby="Zoom"
                                                    onChange={(e) => {
                                                        setZoom(e.target.value)
                                                    }}
                                                    className="zoom-range"
                                                    />
                                                </div>
                                                
                                                <button className="rbt-btn btn-sm mt-4" onClick={handleUpload} type="button" disabled={enviandoFoto}>{enviandoFoto ? 'Subiendo...' : 'Subir Archivo'}</button>
                                                </div>
                                            )}
                                        </div>
                                        
                                    </div>
                                    
                                    {/*Actividades */}
                                    <div className="tab-pane fade" id="actividades" role="tabpanel" aria-labelledby="actividad-tab5">
                                        <div className="content">
                                            <div className="section-title">
                                                <h4 className="rbt-title-style-3">Actividades</h4>
                                            </div>
        
                                            <div className="advance-tab-button mb--30">
                                                <ul className="nav nav-tabs tab-button-style-2 justify-content-start" id="settinsTab-4" role="tablist">
                                                    <li role="presentation">
                                                        <a href="#" className="tab-button active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="true">
                                                            <span className="title">Perfil</span>
                                                        </a>
                                                    </li>
                                                    <li role="presentation">
                                                        <a href="#" className="tab-button" id="password-tab" data-bs-toggle="tab" data-bs-target="#password" role="tab" aria-controls="password" aria-selected="false">
                                                            <span className="title">Contraseña</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
        
                                            <div className="rainbow-tab-content tab-content">
                                                <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    {/* Start Profile Row  */}
                                                    <form action="#" className="rbt-profile-row rbt-default-form row row--15">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="firstname">Nombre</label>
                                                                <input id="firstname" type="text"  />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="lastname">Apellidos</label>
                                                                <input id="lastname" type="text"  />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="username">Nombre de usuario</label>
                                                                <input id="username" type="text"  />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                            <div className="rbt-form-group">
                                                                <label htmlFor="phonenumber">Teléfono</label>
                                                                <input id="phonenumber" type="tel"  />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt--20">
                                                            <div className="rbt-form-group">
                                                                <a className="rbt-btn btn-gradient" href="#">Actualizar</a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    {/* End Profile Row  */}
                                                </div>
        
        
                                            </div>
                                        </div>
                                    </div>
                                    
    
                              </div>
                          </div>
    
                      </div>
                  </div>
              </div>
          </div>
    
        </div>
      )
  }
  
}
export default Perfil;